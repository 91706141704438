import { useCallback, useEffect, useMemo, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { QrcodeErrorCallback, QrcodeSuccessCallback } from 'html5-qrcode/esm/core';
import produce from 'immer';

import { resizeVideoElement } from '../utils/resizeVideoElement';

export type UseQrCodeScannerProps = {
  onScan: (decodedText: string) => void;
  onScanFail: (error: string) => void;
};

export type QRCodeScannerState = {
  scanner: Html5Qrcode | null;
  scanning: boolean;
};

export default function useQrCodeScanner(elementId: string, props: UseQrCodeScannerProps) {
  const { onScan, onScanFail } = props;
  const [state, setState] = useState<QRCodeScannerState>({
    scanner: null,
    scanning: false,
  });
  const onScanSuccess: QrcodeSuccessCallback = useCallback(
    (decodedText, decodedResult) => onScan(decodedText),
    [onScan],
  );
  const onScanFailure: QrcodeErrorCallback = useCallback(
    (error) => {
      onScanFail(error);
    },
    [onScanFail],
  );
  const start = useCallback(async () => {
    if (!!state.scanner && !state.scanning) {
      const scanning = await state.scanner
        .start({ facingMode: 'environment' }, { fps: 30 }, onScanSuccess, onScanFailure)
        .then(() => true)
        .catch((e) => {
          console.warn(e);
          return false;
        });
      setState(
        produce((draft) => {
          draft.scanning = scanning;
        }),
      );
    }
  }, [onScanFailure, onScanSuccess, state.scanner, state.scanning]);
  const stop = useCallback(async () => {
    if (state.scanning) {
      await state.scanner?.stop();
      setState(
        produce((draft) => {
          draft.scanning = false;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.scanner?.stop, state.scanning]);
  useEffect(() => {
    // Note: scanner needs to be instantiated as side effect, otherwise on hook instantiation the elementId matches no DOM Object
    setState(
      produce((draft) => {
        draft.scanner = new Html5Qrcode(elementId, false);
      }),
    );
  }, [elementId]);
  useEffect(() => {
    // resize camera feed once we start scanning
    if (state.scanning) {
      const videoEl = document.getElementById(elementId)?.querySelector('video');
      if (videoEl) {
        // Calling resize before the video elem is "ready" will result in wrong resizing
        videoEl.addEventListener('canplay', () => resizeVideoElement(videoEl));
      }
    }
  }, [elementId, state.scanning]);
  useEffect(() => {
    // Stop canning before dismount
    return () => {
      stop();
    };
  }, [stop]);

  const handler = useMemo(
    () => ({
      start,
      scanning: state.scanning,
      stop,
    }),
    [start, state.scanning, stop],
  );
  return handler;
}
