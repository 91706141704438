import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { checkCameraPermission } from '../utils/checkCameraPermission';

export enum Consent {
  Granted,
  Rejected,
  Pending,
  Unset,
}

const permissionStateMap: Record<PermissionState, Consent> = {
  denied: Consent.Rejected,
  granted: Consent.Granted,
  prompt: Consent.Unset,
};

type UseCameraPermissionState = {
  consented: Consent;
};

export function useCameraPermission() {
  const [state, setState] = useState<UseCameraPermissionState>({
    consented: Consent.Unset,
  });
  const request = useCallback(async () => {
    setState(
      produce((draft) => {
        draft.consented = Consent.Pending;
      }),
    );
    const consent = await navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
        },
      })
      .then(() => Consent.Granted)
      .catch((e) => {
        console.warn(e.message);
        return Consent.Rejected;
      });
    setState(
      produce((draft) => {
        draft.consented = consent;
      }),
    );
    return consent;
  }, []);
  useEffect(() => {
    (async () => {
      const granted = await checkCameraPermission()
        .then((r) => permissionStateMap[r])
        .catch((e) => {
          console.warn('Failed to query');
          return Consent.Unset;
        });
      setState(
        produce((draft) => {
          draft.consented = granted;
        }),
      );
    })();
  }, []);
  const handler = useMemo(() => ({ request, status: state.consented }), [request, state.consented]);
  return handler;
}
