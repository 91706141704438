export async function checkCameraPermission() {
  // note that some browsers not yet support "camera" as part of the permissions API
  // for this reason we need a fallback/error handling behaviour.
  // See https://developer.mozilla.org/en-US/docs/Web/API/Permissions
  // Regarding the permission state see https://w3c.github.io/permissions/#permissions
  const granted = await navigator.permissions
    .query({ name: 'camera' as any })
    .then((r) => r.state)
    .catch((e) => {
      console.warn(e.message || 'Errored on permission query');
      throw e;
    });
  return granted;
}
