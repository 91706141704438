import { ImageAsset } from '../models/ImageAsset';
import { IMAGES } from '../resources/Images';

const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export interface Assets {
  targetSrc: string;
  images: ImageAsset[];
}

export const getAssetsForId = (id: string): Promise<Assets> => {
  if (!ASSETS_API) {
    // NOTE: this is just a hack to get around the requirement of having to duplicate images for composite IDs
    const images = IMAGES.some((s) => s.src.includes(id))
      ? IMAGES.filter((s) => s.src.includes(id))
      : IMAGES.filter((img) => Array.from(id).some((c) => img.src.includes(c)));
    return Promise.resolve({
      images,
      targetSrc: `./assets/targets/${id}.mind`,
    });
  }

  return fetch(`${ASSETS_API}/${id}`).then((r) =>
    r.ok ? r.json() : Promise.reject({ statusCode: r.status, text: r.statusText }),
  );
};
