const allowedDomains = process.env.REACT_APP_HOSTNAME?.split(',') || new Array<string>();

export function isAllowedDomain(rawUrl: string): boolean {
  const url = new URL(rawUrl);
  const domainsToTest = allowedDomains.concat([window.location.hostname]);
  return domainsToTest.some(
    (domain) =>
      domain.localeCompare(url.hostname, undefined, {
        sensitivity: 'base',
      }) === 0,
  );
}
