import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import Fab from '@mui/material/Fab';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import { grey } from '@mui/material/colors';

import { useDrawer } from './useDrawer';

const pulsateAnimation = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}
`;
const drawerBleeding = 28;
const spacing = 4;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  padding: `0 ${theme.spacing(spacing)}`,
  width: "100%",
  hyphens: "auto",
  // Hint: hack to avoid padded empty footer section to be displayed
  '&:empty': {
    display: 'none',
  },
}));

export default function MobileDrawer() {
  const drawerHandler = useDrawer();

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            maxHeight: `calc(65% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        hideBackdrop
        open={drawerHandler.isOpen}
        onClose={drawerHandler.close}
        onOpen={drawerHandler.open}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            minHeight: drawerBleeding,
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Fab
            color="primary"
            sx={{
              boxShadow: (theme) => theme.shadows[2], // base is 6
              position: 'absolute',
              top: -20,
              left: `calc(50% - ${40 / 2}px)`, // widht/height for size=small is 40px
              touchAction: 'none',
              pointerEvents: 'auto',
              zIndex: 1500,
              animation: `${pulsateAnimation} 0.75s ease-in-out infinite both`,
              animationPlayState: !drawerHandler.isOpen && drawerHandler.isAnimated ? 'running' : 'paused',
            }}
            onClick={drawerHandler.toggle}
            size="small"
          >
            {drawerHandler.isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Fab>
        </StyledBox>
        <Box sx={{ overflow: 'auto' }}>
          <StyledBox ref={drawerHandler.titleRef}></StyledBox>
          <StyledBox
            ref={drawerHandler.contentRef}
            sx={{
              mt: spacing / 3,
              height: '100%',
              'img.mode__visual': {
                maxHeight: '160px',
              },
            }}
          ></StyledBox>
        </Box>
        <StyledBox sx={{ py: `${drawerBleeding}px` }} ref={drawerHandler.actionsRef}></StyledBox>
      </SwipeableDrawer>
    </>
  );
}
