import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './vendor/mind-ar/dist/mindar-image.prod.js';
import 'aframe';
import './vendor/mind-ar/dist/mindar-image-aframe.prod.js';

import './fonts.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { router } from './Routes';
import LayoutDrawer from './components/LayoutDrawer';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    drawerTitle: React.CSSProperties;
    drawerSubtitle: React.CSSProperties;
    drawerContent: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    drawerTitle?: React.CSSProperties;
    drawerSubtitle?: React.CSSProperties;
    drawerContent?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    drawerTitle: true;
    drawerSubtitle: true;
    drawerContent: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#cc1317',
    },
    mode: 'light',
    background: {
      default: '#cccccc  ',
    },
  },
  typography: {
    fontFamily: ['"Frutiger Neue Light"', 'sans-serif'].join(','),
    drawerTitle: {
      fontStyle: 'normal',
      fontSize: '21px',
      fontWeight: '600',
      lineHeight: '32px',
    },
    drawerSubtitle: {
      fontStyle: 'normal',
      fontSize: '21px',
      fontWeight: '400',
      lineHeight: '32px',
    },
    drawerContent: {
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '28px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          body1: 'p',
          body2: 'p',
          inherit: 'p',
          drawerTitle: 'h1',
          drawerSubtitle: 'h2',
          drawerContent: 'p',
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LayoutDrawer>
        <RouterProvider router={router} />
      </LayoutDrawer>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
