import { useCallback, useContext, useMemo } from 'react';

import { DrawerContext, DrawerContextValue } from './DrawerProvider';

export type UseDrawerValue = Pick<
  DrawerContextValue,
  'titleRef' | 'contentRef' | 'actionsRef' | 'isOpen' | 'isAnimated'
> & {
  close: () => void;
  open: () => void;
  toggle: () => void;
  animate: (s: boolean) => void;
};

export function useDrawer(): UseDrawerValue {
  const context = useContext(DrawerContext);
  const close = useCallback(() => {
    context?.setIsOpen(false);
  }, [context]);
  const open = useCallback(() => {
    context?.setIsOpen(true);
  }, [context]);
  const toggle = useCallback(() => {
    context?.setIsOpen(!context.isOpen);
  }, [context]);
  const animate = useCallback(
    (v: boolean) => {
      context?.setIsAnimated(v);
    },
    [context],
  );
  const value = useMemo<UseDrawerValue>(
    () => ({
      isOpen: context?.isOpen || false,
      contentRef: context?.contentRef || null,
      titleRef: context?.titleRef || null,
      actionsRef: context?.actionsRef || null,
      isAnimated: context?.isAnimated || false,
      animate,
      close,
      open,
      toggle,
    }),
    [
      animate,
      close,
      context?.actionsRef,
      context?.contentRef,
      context?.isAnimated,
      context?.isOpen,
      context?.titleRef,
      open,
      toggle,
    ],
  );
  return value;
}
