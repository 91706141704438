import { useCallback, useEffect, useRef } from 'react';
import { Entity, Scene } from 'aframe';

import { ImageAsset } from '../models/ImageAsset';

const idGenerator = (ix: number) => `asset_${ix}`;

function calcHeightToWidthQuotient(img: HTMLImageElement): number {
  return img.naturalHeight / img.naturalWidth;
}


type ASceneElement = Scene;
type AEntityElement = Entity;

export type MindArParams = Partial<{
  filterMinCF: number;
  filterBeta: number;
}>;

export type MindARSceneProps = MindArParams & {
  images: ImageAsset[];
  targetSrc: string;
  onTargetFound: (targetIndex: number) => void;
  onTargetLost: (targetIndex: number) => void;
};

export type MindARSceneState = {
  quotients: Record<string, string>;
};

export default function MindARScene(props: MindARSceneProps) {
  // mind-ar  params as described in https://hiukim.github.io/mind-ar-js-doc/quick-start/tracking-config
  // defaults are: filterBeta=1000, filterMinCF=0.001
  const { images, targetSrc, filterBeta = 1000, filterMinCF = 0.001, onTargetFound, onTargetLost } = props;
  const sceneRef = useRef<ASceneElement>(null);
  const imageRefs = useRef<Array<HTMLImageElement | null>>(new Array<HTMLImageElement | null>());
  const entityRefs = useRef<AEntityElement[]>(new Array<AEntityElement>());
  const getImageQuotient = useCallback((ix: number) => {
    const targetImage = imageRefs.current[ix];
    if (!targetImage) return 0;
    const quotient = calcHeightToWidthQuotient(targetImage);
    return quotient;
  }, []);
  useEffect(() => {
    return () => {
      // Clear overlays
      const mindArOverlays = document.querySelectorAll('.mindar-ui-overlay');
      mindArOverlays.forEach((e) => e.remove());
    };
  }, []);
  useEffect(() => {
    // Register target event handlers
    const currentRefs = entityRefs.current;
    const handlers = currentRefs.map((e, ix) => ({ found: () => onTargetFound(ix), lost: () => onTargetLost(ix) }));
    currentRefs?.forEach((ref, ix) => {
      const handler = handlers[ix];
      ref?.addEventListener('targetFound', handler.found);
      ref?.addEventListener('targetLost', handler.lost);
    });

    return () => {
      currentRefs?.forEach((ref, ix) => {
        const handler = handlers[ix];
        ref?.removeEventListener('targetFound', handler.found);
        ref?.removeEventListener('targetLost', handler.lost);
      });
    };
  }, [onTargetFound, onTargetLost]);

  return (
    <a-scene
      // TODO: remove cast once better jsx types land
      ref={sceneRef as any}
      mindar-image={`imageTargetSrc: ${targetSrc}; autoStart: true; uiError: no; uiLoading: yes; uiScanning: yes; filterMinCF:${filterMinCF}; filterBeta:${filterBeta};`}
      color-space="sRGB"
      embedded='true'
      renderer="colorManagement: true, physicallyCorrectLights"
      vr-mode-ui="enabled: false"
      device-orientation-permission-ui="enabled: false"
    >
      <a-assets>
        {images.map((image, ix) => (
          <img
            ref={(elem) => {
              imageRefs.current[ix] = elem;
            }}
            key={image.src}
            id={idGenerator(ix)}
            src={image.src}
            alt="Asset display"
          />
        ))}
      </a-assets>
      <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>
      {images.map((image, ix) => (
        <a-entity
          key={image.src}
          mindar-image-target={`targetIndex: ${ix}`}
          ref={(elem: AEntityElement) => {
            entityRefs.current[ix] = elem;
          }}
        >
          <a-plane
            src={`#${idGenerator(ix)}`}
            width="1"
            height={getImageQuotient(ix).toString()}
            position="0 0 0"
            rotation="0 0 0"
          ></a-plane>
        </a-entity>
      ))}
    </a-scene>
  );
}
