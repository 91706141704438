import { createContext, MutableRefObject } from 'react';

export type DrawerContextValue = {
  titleRef: MutableRefObject<any> | null;
  contentRef: MutableRefObject<any> | null;
  actionsRef: MutableRefObject<any> | null;
  isOpen: boolean;
  setIsOpen: (s: boolean) => void;
  isAnimated: boolean;
  setIsAnimated: (s: boolean) => void;
};

export const DrawerContext = createContext<DrawerContextValue | null>(null);

export const DrawerContextProvider = DrawerContext.Provider;
