import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import produce from 'immer';

import { ScanMode } from '../models/ScanMode';
import PaintScanMode from '../components/PaintScanMode';
import QRCodeScanMode from '../components/QRCodeScanMode';
import ConsentView from '../components/ConsentView';
import { useDrawer } from '../components/LayoutDrawer';
import { Consent as CameraConsent, useCameraPermission } from '../hooks/useCameraPermission';

export type RootState = {
  cameraConsentWarningRead: boolean;
};

export default function Root() {
  const [state, setState] = useState<RootState>({
    cameraConsentWarningRead: false,
  });
  const cameraPermission = useCameraPermission();
  const drawer = useDrawer();
  const [params] = useSearchParams();
  const mode = useMemo(() => {
    const id = params.get('id');
    return !!id ? ScanMode.Paint : ScanMode.QRCode;
  }, [params]);
  const handleRequestPermission = cameraPermission.status === CameraConsent.Granted || state.cameraConsentWarningRead;

  const handleCameraConsentHintRead = useCallback(async () => {
    setState(
      produce((draft) => {
        draft.cameraConsentWarningRead = true;
      }),
    );
  }, []);

  useEffect(() => {
    drawer.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {handleRequestPermission ? (
        <>
          {mode === ScanMode.Paint && <PaintScanMode />}
          {mode === ScanMode.QRCode && <QRCodeScanMode />}
        </>
      ) : (
        <ConsentView
          cameraPermission={cameraPermission.status}
          onRequestCameraPermission={handleCameraConsentHintRead}
        />
      )}
    </Box>
  );
}
