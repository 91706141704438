import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import QrCodeScanner from '@mui/icons-material/QrCodeScannerOutlined';
import produce from 'immer';

import QRCodeScan from '../assets/QRCodeScan.png';
import useQrCodeScanner from '../hooks/useQrCodeScanner';
import { DrawerContent, DrawerTitle } from './LayoutDrawer';
import { isAllowedDomain } from '../utils/isAllowedDomain';

type QRCodeScanModeState = {
  invalidCodeScanned: boolean;
};

export default function QRCodeScanMode() {
  const [state, setState] = useState<QRCodeScanModeState>({
    invalidCodeScanned: false,
  });
  const scannerId = useId();
  const theme = useTheme();
  const [, setSearchParams] = useSearchParams();
  const overlayColor = useMemo(
    () => (state.invalidCodeScanned ? theme.palette.error.main : theme.palette.background.paper),
    [state.invalidCodeScanned, theme.palette.background.paper, theme.palette.error.main],
  );

  const onScan = useCallback(
    (decodedText: string) => {
      console.log('Matched: ', decodedText);
      if (isAllowedDomain(decodedText)) {
        const decodedUrl = new URL(decodedText);
        const decodedUrlId = decodedUrl.searchParams.get('id');
        if (!!decodedUrlId) {
          setSearchParams((prevParams) => {
            const nextParams = new URLSearchParams(prevParams);
            nextParams.set('id', decodedUrlId);
            return nextParams;
          });
        }
      } else {
        console.warn('Not allowed');
        setState(
          produce((draft) => {
            draft.invalidCodeScanned = true;
          }),
        );
      }
    },
    [setSearchParams],
  );
  const onScanFail = useCallback(() => {}, []);
  const scanner = useQrCodeScanner(scannerId, {
    onScan,
    onScanFail,
  });

  useEffect(() => {
    let timeoutHandler: NodeJS.Timeout | null = null;
    if (state.invalidCodeScanned) {
      timeoutHandler = setTimeout(
        () =>
          setState(
            produce((draft) => {
              draft.invalidCodeScanned = false;
            }),
          ),
        2500,
      );
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [state.invalidCodeScanned]);
  useEffect(() => {
    scanner.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanner.start]);

  return (
    <>
      <Box
        sx={{ height: '100%', width: '100%', flex: 1, display: 'flex', alignItems: 'stretch', position: 'relative' }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ position: 'absolute', width: '100%', height: '100%', py: 8, px: 4, zIndex: 50 }}
        >
          <QrCodeScanner
            sx={{
              color: overlayColor,
              opacity: 0.33,
              height: 'auto',
              width: {
                xs: '50%',
                md: '320px',
              },
            }}
          />
        </Stack>
        <Box id={scannerId} sx={{ width: '100%', height: '100%' }}></Box>
      </Box>
      <>
        <DrawerTitle>
          <Typography variant="drawerTitle">QR-Code scannen</Typography>
        </DrawerTitle>
        <DrawerContent>
          <Stack spacing={1}>
            <Typography variant="drawerSubtitle">
              Scanne den QR-Code neben dem Bild um mit dem Blick dahinter zu starten.
            </Typography>
            <Box sx={{ alignSelf: 'center', flexShrink: 1 }}>
              <img className="mode__visual" src={QRCodeScan} alt="QR Code scan ilustration" />
            </Box>
          </Stack>
        </DrawerContent>
      </>
    </>
  );
}
