import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import produce from 'immer';

import { DrawerContextProvider, DrawerContextValue } from './DrawerProvider';
import MobileDrawer from './MobileDrawer';

type LayoutDrawerState = {
  isOpen: boolean;
  isAnimated: boolean;
};

export type LayoutDrawerProps = PropsWithChildren;

export default function LayoutDrawer(props: LayoutDrawerProps) {
  const { children } = props;
  const [state, setState] = useState<LayoutDrawerState>({
    isOpen: false,
    isAnimated: false,
  });
  // TODO: add correct types
  const titleRef = useRef<any>(null);
  const contentRef = useRef<any>(null);
  const actionsRef = useRef<any>(null);
  const setIsOpen = useCallback(
    (s: boolean) =>
      setState(
        produce((draft) => {
          draft.isOpen = s;
        }),
      ),
    [],
  );
  const setIsAnimated = useCallback(
    (s: boolean) =>
      setState(
        produce((draft) => {
          draft.isAnimated = s;
        }),
      ),
    [],
  );
  const drawerState = useMemo<DrawerContextValue>(
    () => ({
      isOpen: state.isOpen,
      setIsOpen,
      contentRef,
      titleRef,
      actionsRef,
      isAnimated: state.isAnimated,
      setIsAnimated,
    }),
    [setIsAnimated, setIsOpen, state.isAnimated, state.isOpen],
  );
  const isMobile = true;

  return (
    <DrawerContextProvider value={drawerState}>
      <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      {isMobile ? <MobileDrawer /> : null}
    </DrawerContextProvider>
  );
}
