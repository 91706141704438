import Portal from '@mui/material/Portal';
import { PropsWithChildren } from 'react';

import { useDrawer } from './useDrawer';

export default function DrawerTitle(props: PropsWithChildren) {
  const { children } = props;
  const drawer = useDrawer();

  return drawer.titleRef?.current && <Portal container={drawer.titleRef.current}>{children}</Portal>;
}
