// Note: resize the video feed to match the dimensions used by mind-ar.
// This way the UX is less "jumpy"
// Taken from https://github.com/hiukim/mind-ar-js/blob/master/src/image-target/aframe.js#L155
export function resizeVideoElement(video: HTMLVideoElement): void {
  const container = video.parentElement!;
  if (!!container) {
    let vw: number, vh: number;
    const videoRatio = video.videoWidth / video.videoHeight;
    const containerRatio = container.clientWidth / container.clientHeight;
    console.debug({ videoRatio, containerRatio });
    if (videoRatio > containerRatio) {
      vh = container.clientHeight;
      vw = vh * videoRatio;
    } else {
      vw = container.clientWidth;
      vh = vw / videoRatio;
    }
    console.log('Resizing!');
    video.style.position = 'absolute';
    video.style.top = -(vh - container.clientHeight) / 2 + 'px';
    video.style.left = -(vw - container.clientWidth) / 2 + 'px';
    video.style.width = vw + 'px';
    video.style.height = vh + 'px';
  } else {
    console.warn('Video element has no parent element!');
  }
}
