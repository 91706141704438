import Portal from '@mui/material/Portal';
import { PropsWithChildren } from 'react';

import { useDrawer } from './useDrawer';

export default function DrawerActions(props: PropsWithChildren) {
  const { children } = props;
  const drawer = useDrawer();

  return drawer.actionsRef?.current && <Portal container={drawer.actionsRef.current}>{children}</Portal>;
}
