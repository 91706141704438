import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import QRCodeScanner from '@mui/icons-material/QrCodeScannerOutlined';

import PaintScan from '../assets/PaintScan.png';
import { getAssetsForId, Assets } from '../api/assets';
import { ImageAsset } from '../models/ImageAsset';
import MindARScene, { MindArParams } from '../components/MindARScene';
import { toFloatOrUndefined } from '../utils/toFloatOrUndefined';
import { DrawerActions, DrawerContent, DrawerTitle, useDrawer } from './LayoutDrawer';

type PaintScanModeState = {
  scene: Assets | null;
  match: ImageAsset | null;
  matchInScreen: boolean;
};

export default function PaintScanMode() {
  const [state, setState] = useState<PaintScanModeState>({
    scene: null,
    match: null,
    matchInScreen: false,
  });
  const { scene, match } = state;
  const drawer = useDrawer();
  const [params, setParams] = useSearchParams();
  const mindArParams = useMemo<MindArParams>(() => {
    const rawValues = ['filterMinCF', 'filterBeta'].map((v) => params.get(v));
    const parsedValues = rawValues.map(toFloatOrUndefined);
    const [filterMinCF, filterBeta] = parsedValues;
    return { filterMinCF, filterBeta };
  }, [params]);
  const id = params.get('id');
  const handleSearchCode = useCallback(() => {
    setParams((prevParams) => {
      const nextParams = new URLSearchParams(prevParams);
      nextParams.delete('id');
      return nextParams;
    });
  }, [setParams]);

  const handleTargetFound = useCallback((e: number) => {
    setState(
      produce((draft) => {
        if (!!draft.scene) {
          const match = draft.scene.images[e];
          draft.match = match;
          draft.matchInScreen = true;
        }
      }),
    );
  }, []);
  const handleTargetLost = useCallback((e: number) => {
    setState(
      produce((draft) => {
        draft.matchInScreen = false;
      }),
    );
  }, []);

  useEffect(() => {
    drawer.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);
  useEffect(() => {
    drawer.animate(state.matchInScreen);
  }, [drawer, state.matchInScreen]);
  useEffect(() => {
    (async () => {
      // TODO: handle error in fetch
      const assets = !!id
        ? await getAssetsForId(id).catch((e) => {
            console.error(e);
            return null;
          })
        : null;
      setState(
        produce((draft) => {
          draft.scene = assets;
        }),
      );
    })();
  }, [id]);

  return (
    <>
      <Box
        sx={{ height: '100%', width: '100%', flex: 1, display: 'flex', alignItems: 'stretch', position: 'relative' }}
      >
        <Fab
          onClick={handleSearchCode}
          sx={{ position: 'absolute', bottom: 72, right: 20 }}
          variant="extended"
          color="primary"
          size="medium"
        >
          <QRCodeScanner sx={{ mr: 1 }} />
          Neuen QR-Code scannen
        </Fab>
        {!!scene && (
          <MindARScene
            targetSrc={scene.targetSrc}
            images={scene.images}
            {...mindArParams}
            onTargetFound={handleTargetFound}
            onTargetLost={handleTargetLost}
          />
        )}
      </Box>
      {match ? (
        <>
          <DrawerTitle>
            <Typography
              variant="drawerTitle"
              sx={{
                color: (theme) => theme.palette.success.light,
              }}
            >
              {match.title}
            </Typography>
            <Typography variant="drawerTitle">{match.autors.join(', ')}</Typography>
          </DrawerTitle>
          <DrawerContent>
            <Stack spacing={1}>
              <Box py={2}>
                <Typography variant="drawerContent">{match.description}</Typography>
              </Box>
            </Stack>
          </DrawerContent>
          <DrawerActions>
            <Button
              fullWidth
              onClick={handleSearchCode}
              color="primary"
              variant="outlined"
              startIcon={<QRCodeScanner />}
            >
              Neuen QR-Code scannen
            </Button>
          </DrawerActions>
        </>
      ) : (
        <>
          <DrawerTitle>
            <Typography variant="drawerTitle">Bild wird gesucht …</Typography>
          </DrawerTitle>
          <DrawerContent>
            <Stack spacing={1}>
              <Typography variant="drawerSubtitle">
                Halte dein Telefon ruhig vor das Bild. Geh etwas näher ran oder weiter weg, bis das Bild erkannt wird.
              </Typography>
              <Box sx={{ alignSelf: 'center', flexShrink: 1 }}>
                <img className="mode__visual" src={PaintScan} alt="Paint scan ilustration" />
              </Box>
            </Stack>
          </DrawerContent>
          <DrawerActions>
            <Button
              fullWidth
              onClick={handleSearchCode}
              color="primary"
              variant="outlined"
              startIcon={<QRCodeScanner />}
            >
              Neuen QR-Code scannen
            </Button>
          </DrawerActions>
        </>
      )}
    </>
  );
}
