import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import { PropsWithChildren } from 'react';

import { Consent as CameraConsent } from '../hooks/useCameraPermission';
import { DrawerActions, DrawerContent, DrawerTitle } from './LayoutDrawer';

function DrawerCard(props: PropsWithChildren<{ title: string; content: string }>) {
  const { title, content, children } = props;

  return (
    <>
      <DrawerTitle>
        <Typography variant="drawerTitle">{title}</Typography>
      </DrawerTitle>
      <DrawerContent>
        <Typography variant="drawerSubtitle">{content}</Typography>
      </DrawerContent>
      {children && <DrawerActions>{children}</DrawerActions>}
    </>
  );
}

export type ConsentViewProps = {
  cameraPermission: CameraConsent;
  onRequestCameraPermission: () => void;
};

export default function ConsentView(props: ConsentViewProps) {
  const { cameraPermission, onRequestCameraPermission } = props;

  return (
    <>
      <Stack justifyContent="start" alignItems="center" sx={{ flex: 1, px: 4, py: 7 }}>
        <Typography sx={{ color: (theme) => theme.palette.success.light }} variant="h5">
          Bitte erlaube den Zugriff auf Deine Kamera.
        </Typography>
      </Stack>
      {cameraPermission !== CameraConsent.Rejected && (
        <DrawerCard
          title="Willkommen beim Blick dahinter"
          content="Bitte erlaube den Zugriff auf Deine Kamera, dann geht es auch gleich los."
        >
          <LoadingButton
            fullWidth
            variant="contained"
            loading={cameraPermission === CameraConsent.Pending}
            onClick={onRequestCameraPermission}
            startIcon={<PhotoCameraIcon />}
          >
            Zugriff auf Kamera erlauben
          </LoadingButton>
        </DrawerCard>
      )}
      {cameraPermission === CameraConsent.Rejected && (
        <DrawerCard
          title="Kamera Berechtigung"
          content="Die Anwendung benötigt Zugriff auf Ihr Kamera. Zugriff wurde verweigert und die App kann daher nicht starten. Bitte setzten Sie die Verweigerung zurück."
        />
      )}
    </>
  );
}
